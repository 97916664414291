import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.23.5_next@14.2.16_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_re_vfoc2ly5aqft6isbm5xqyyml3i/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.23.5_next@14.2.16_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_re_vfoc2ly5aqft6isbm5xqyyml3i/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.23.5_next@14.2.16_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_re_vfoc2ly5aqft6isbm5xqyyml3i/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.16_@babel+core@7.25.9_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AkLogo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/ConnectAndGet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/DownloadApp.section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/ExploreSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/HowItWorksSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/LandingHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/LenisProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/StatsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/TopGirls.section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/app/src/components/ui/separator.tsx");
