'use client';

import { motion, useMotionValueEvent, useScroll } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React, { useEffect } from 'react';
import { FiLogIn } from 'react-icons/fi';
import AkLogo from '../AkLogo';
import { Button } from '../ui/button';
import { useLocale, useTranslations } from 'next-intl';
import LangSwitcher from '../shared/LangSwitcher';
const LandingHeader = () => {
  const locale = useLocale();
  const t = useTranslations('landingPage');
  const router = useRouter();
  const [shrinkMenu, setShrinkMenu] = React.useState<boolean>(false);
  const [windowHeight, setWindowHeight] = React.useState<number>(0);
  const {
    scrollY
  } = useScroll();
  useMotionValueEvent(scrollY, 'change', latest => {
    if (latest > windowHeight - windowHeight * 0.9) {
      setShrinkMenu(true);
    } else {
      setShrinkMenu(false);
    }
  });
  useEffect(() => {
    setWindowHeight(window.innerHeight);
    const resize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);
  return <div className="relative" data-sentry-component="LandingHeader" data-sentry-source-file="LandingHeader.tsx">
            <motion.div initial={{
      opacity: 0,
      y: -50
    }} animate={{
      opacity: 1,
      y: 0,
      height: shrinkMenu ? 60 : 90,
      background: shrinkMenu ? 'hsla(0, 0%, 0%, .3)' : 'hsla(0, 0%, 0%, 0)',
      backdropFilter: shrinkMenu ? 'blur(25px)' : 'blur(0px)'
    }} transition={{
      delay: 3.5,
      type: 'spring',
      stiffness: 500,
      height: {
        type: 'just'
      },
      background: {
        type: 'just'
      },
      backdropFilter: {
        type: 'just'
      }
      // y: {
      //     delay: 1.2
      // },
      // opacity: {
      //     delay: 3.5
      // }
    }} className="blurBg fixed inset-x-0 z-[99999] flex w-full origin-top items-center justify-between px-8" data-sentry-element="unknown" data-sentry-source-file="LandingHeader.tsx">
                <div className="mx-auto flex w-full max-w-6xl items-center justify-between">
                    <div className="flex items-center gap-5 text-3xl">
                        <Link href="/" data-sentry-element="Link" data-sentry-source-file="LandingHeader.tsx">
                            <AkLogo fill="white" initial={{
              height: 60
            }} animate={{
              height: shrinkMenu ? 40 : 60
            }} data-sentry-element="AkLogo" data-sentry-source-file="LandingHeader.tsx" />
                        </Link>
                    </div>
                    <div className="flex items-center gap-4">
                        <LangSwitcher locale={locale} data-sentry-element="LangSwitcher" data-sentry-source-file="LandingHeader.tsx" />
                        <Button variant="gradient" gradientAnimation="on" asChild className="cursor-pointer overflow-hidden p-0 tracking-widest" onClick={() => router.push('/auth/login')} data-sentry-element="Button" data-sentry-source-file="LandingHeader.tsx">
                            <motion.div animate={{
              height: shrinkMenu ? 35 : 50,
              width: shrinkMenu ? 180 : 180
            }} transition={{
              delay: 0
            }} data-sentry-element="unknown" data-sentry-source-file="LandingHeader.tsx">
                                <motion.span className="absolute flex w-full items-center justify-center gap-2 overflow-hidden font-semibold" initial={{
                opacity: 0,
                y: 10
              }} animate={{
                opacity: 1,
                y: 0
              }} exit={{
                opacity: 0,
                y: -10
              }} data-sentry-element="unknown" data-sentry-source-file="LandingHeader.tsx">
                                    {t('intro.login')}
                                    <FiLogIn size={20} data-sentry-element="FiLogIn" data-sentry-source-file="LandingHeader.tsx" />
                                </motion.span>
                                {/* <AnimatePresence mode="sync">
                                 {!shrinkMenu ? (
                                    ) : (
                                        <motion.div
                                        initial={{
                                            opacity: 0,
                                            y: 10
                                            }}
                                            animate={{
                                                opacity: 1,
                                                y: 0
                                                }}
                                                exit={{
                                                    opacity: 0,
                                                    y: -10
                                                    }}
                                                    className="absolute flex items-center justify-center">
                                                    <FiLogIn size={20} />
                                                    </motion.div>
                                                    )}
                                                    </AnimatePresence> */}
                            </motion.div>
                        </Button>
                    </div>
                </div>
            </motion.div>
        </div>;
};
export default LandingHeader;