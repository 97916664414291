'use client';

import { Button } from '@/components/ui/button';
import { useWindowSize } from '@uidotdev/usehooks';
import { motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import React from 'react';
const GirlsTab = () => {
  const t = useTranslations('landingPage');
  const [expanded, setExpanded] = React.useState(false);
  const width = useWindowSize().width ?? 0;
  const isMobile = width < 1024;
  return <div className="grid grid-cols-1 gap-4 lg:grid-cols-2" data-sentry-component="GirlsTab" data-sentry-source-file="GirlsTab.tsx">
            <div className="relative order-2 aspect-video w-full lg:order-1">
                <Image src="/landing/2.png" alt="demo" fill className="absolute overflow-hidden rounded-md object-cover" data-sentry-element="Image" data-sentry-source-file="GirlsTab.tsx" />
            </div>
            <div className="order-1 flex h-full flex-col justify-center lg:order-2">
                <p className="">{t('girlsTab.text1')}</p>
                {isMobile ? <>
                        <motion.p initial={{
          opacity: 0,
          height: 0,
          overflow: 'hidden',
          paddingTop: 0
        }} animate={{
          opacity: expanded ? 1 : 0,
          height: expanded ? 'auto' : 0,
          overflow: 'hidden',
          paddingTop: expanded ? 15 : 0
        }} className="">
                            {t('girlsTab.text2')}
                        </motion.p>
                        <Button variant="ghost" size="sm" className="bg-transparent hover:bg-transparent" onClick={() => setExpanded(!expanded)}>
                            {expanded ? t('intro.showless') : t('intro.showmore')}
                        </Button>
                    </> : <p className="pt-4">{t('girlsTab.text2')}</p>}
            </div>
        </div>;
};
export default GirlsTab;