'use client';

import { cn } from '@/lib/utils';
import { motion, useScroll, useTransform } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import MaxWidthWrapper from '../MaxWidthWrapper';
import { buttonVariants } from '../ui/button';
import { useTranslations } from 'next-intl';
const ConnectAndGet = () => {
  const t = useTranslations('landingPage');
  const sectionRef = React.useRef<HTMLDivElement>(null);
  const {
    scrollYProgress
  } = useScroll({
    target: sectionRef,
    offset: ['start end', 'end start']
  });
  const {
    scrollYProgress: scrollYAk
  } = useScroll({
    target: sectionRef,
    offset: ['30% end', '70% start']
  });
  const y = useTransform(scrollYProgress, [0, 1], ['-50%', '-100%']);
  return <div ref={sectionRef} className="relative overflow-hidden" data-sentry-component="ConnectAndGet" data-sentry-source-file="ConnectAndGet.tsx">
            <motion.div className="absolute inset-0 -z-10 h-[200%] w-full" style={{
      top: y
    }} data-sentry-element="unknown" data-sentry-source-file="ConnectAndGet.tsx">
                <Image src="/landing/5.jpg" fill alt="" className="hidden h-full w-full object-cover object-bottom lg:block" data-sentry-element="Image" data-sentry-source-file="ConnectAndGet.tsx" />
                <Image src="/landing/5m.jpeg" fill alt="" className="block h-full w-full object-cover lg:hidden" data-sentry-element="Image" data-sentry-source-file="ConnectAndGet.tsx" />
            </motion.div>
            <div className="relative w-full px-8 py-8">
                <div className="defaultGradient absolute inset-0 z-10 opacity-80" />
                <MaxWidthWrapper data-sentry-element="MaxWidthWrapper" data-sentry-source-file="ConnectAndGet.tsx">
                    <div style={{
          gridTemplateColumns: '4fr 1fr'
        }} className="relative z-20 flex flex-col items-center justify-center gap-3 md:grid">
                        <div className="">
                            <h1 className="font-semibold">{t('banner.registerToday')}</h1>
                            <p>{t('banner.coinsUsage')}</p>
                        </div>
                        <Link href="/auth/register" className={cn(buttonVariants({
            variant: 'secondary'
          }), 'w-full')} data-sentry-element="Link" data-sentry-source-file="ConnectAndGet.tsx">
                            {t('banner.register')}
                        </Link>
                    </div>
                </MaxWidthWrapper>
            </div>

            <div className="relative w-full px-8 py-8">
                <div className="girlGradient absolute inset-0 z-10 opacity-80" />
                <MaxWidthWrapper data-sentry-element="MaxWidthWrapper" data-sentry-source-file="ConnectAndGet.tsx">
                    <div style={{
          gridTemplateColumns: '4fr 1fr'
        }} className="relative z-20 flex flex-col items-center justify-center gap-3 md:grid">
                        <div className="">
                            <span>
                                <h1 className="font-semibold">{t('banner.chanceGirls')}</h1>
                            </span>
                            <p>{t('banner.likePosting')}</p>
                        </div>
                        <Link href="/ak" className={buttonVariants({
            variant: 'secondary',
            className: 'w-full'
          })} data-sentry-element="Link" data-sentry-source-file="ConnectAndGet.tsx">
                            {t('banner.becomeAK')}
                        </Link>
                    </div>
                </MaxWidthWrapper>
            </div>
        </div>;
};
export default ConnectAndGet;