'use client';

import { Variants, motion, useInView, useScroll, useTransform } from 'framer-motion';
import Image from 'next/image';
import React from 'react';
import MaxWidthWrapper from '../MaxWidthWrapper';
import { bbuttonVariants, Button, buttonVariants } from '../ui/button';
import { Dialog, DialogClose, DialogContent, DialogTrigger } from '../ui/dialog';
import { FiX } from 'react-icons/fi';
import { FaAndroid, FaApple } from 'react-icons/fa';
import { useTranslations } from 'next-intl';
import { cn } from '@/lib/utils';
const variants: Variants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1
  }
};
const DownloadAppSection = () => {
  const t = useTranslations('landingPage.downloadApp');
  const sectionRef = React.useRef<HTMLDivElement>(null);
  const inView = useInView(sectionRef, {
    amount: 0.5
  });
  const {
    scrollYProgress
  } = useScroll({
    target: sectionRef,
    offset: ['start end', 'end start']
  });
  const y = useTransform(scrollYProgress, [0, 1], ['-100%', ' 0%']);
  return <motion.div className="relative border py-11" variants={variants} ref={sectionRef} data-sentry-element="unknown" data-sentry-component="DownloadAppSection" data-sentry-source-file="DownloadApp.section.tsx">
            <div className="absolute inset-0 h-full w-full overflow-hidden">
                <motion.div className="absolute inset-0 -z-10 h-[200%]" style={{
        top: y
      }} data-sentry-element="unknown" data-sentry-source-file="DownloadApp.section.tsx">
                    <div className="defaultGradient absolute inset-0 z-10 opacity-60" />
                    <Image src="/landing/phonegirl.jpg" fill className="object-cover" alt="" data-sentry-element="Image" data-sentry-source-file="DownloadApp.section.tsx" />
                </motion.div>
            </div>
            <MaxWidthWrapper className="flex w-full flex-col items-center justify-start md:grid md:grid-cols-[1fr_2fr]" data-sentry-element="MaxWidthWrapper" data-sentry-source-file="DownloadApp.section.tsx">
                <div style={{
        clipPath: 'inset(0 0 0 0)'
      }} className="absolute bottom-0 order-2 aspect-square w-full max-w-96 md:order-1 md:w-1/3">
                    <motion.div className="absolute aspect-square w-full" initial={{
          y: '100%'
        }} transition={{
          type: 'spring',
          stiffness: 100
        }} animate={{
          y: inView ? 100 : '100%'
        }} data-sentry-element="unknown" data-sentry-source-file="DownloadApp.section.tsx">
                        <Image src="/landing/phonemock.webp" fill className="object-contain object-bottom" alt="" data-sentry-element="Image" data-sentry-source-file="DownloadApp.section.tsx" />
                    </motion.div>
                </div>
                <div className="order-2 mx-5 block h-[300px] w-1/3 md:order-1 md:h-auto" />
                <div className="order-1 space-y-5 md:order-3">
                    <div className="">
                        <span>
                            <h1 className="font-semibold">{t('title')}</h1>
                        </span>
                        <p>{t('description')}</p>
                    </div>
                    <Dialog data-sentry-element="Dialog" data-sentry-source-file="DownloadApp.section.tsx">
                        <DialogTrigger className={cn(buttonVariants({
            variant: 'default',
            gradientAnimation: 'on'
          }), 'flex items-center gap-2')} data-sentry-element="DialogTrigger" data-sentry-source-file="DownloadApp.section.tsx">
                            <FaAndroid data-sentry-element="FaAndroid" data-sentry-source-file="DownloadApp.section.tsx" />
                            <span>Android</span>
                        </DialogTrigger>
                        <DialogContent className="h-[100dvh] w-full bg-black" data-sentry-element="DialogContent" data-sentry-source-file="DownloadApp.section.tsx">
                            <DialogClose data-sentry-element="DialogClose" data-sentry-source-file="DownloadApp.section.tsx">
                                <span className="absolute right-4 top-4 z-ultr bg-primary p-1">
                                    <FiX size={28} data-sentry-element="FiX" data-sentry-source-file="DownloadApp.section.tsx" />
                                </span>
                            </DialogClose>
                            <video controls playsInline src="/tutorial/android.mp4" className="absolute left-0 top-0 max-h-[100dvh] w-full object-contain" />
                        </DialogContent>
                    </Dialog>
                    <Dialog data-sentry-element="Dialog" data-sentry-source-file="DownloadApp.section.tsx">
                        <DialogTrigger className={cn(buttonVariants({
            variant: 'default',
            gradientAnimation: 'on'
          }), 'flex items-center gap-2')} data-sentry-element="DialogTrigger" data-sentry-source-file="DownloadApp.section.tsx">
                            <FaApple data-sentry-element="FaApple" data-sentry-source-file="DownloadApp.section.tsx" />
                            iOS
                        </DialogTrigger>
                        <DialogContent className="h-[100dvh] w-full bg-black" data-sentry-element="DialogContent" data-sentry-source-file="DownloadApp.section.tsx">
                            <DialogClose data-sentry-element="DialogClose" data-sentry-source-file="DownloadApp.section.tsx">
                                <span className="absolute right-4 top-4 z-ultr bg-primary p-1">
                                    <FiX size={28} data-sentry-element="FiX" data-sentry-source-file="DownloadApp.section.tsx" />
                                </span>
                            </DialogClose>
                            <video controls playsInline src="/tutorial/apple.mp4" className="absolute left-0 top-0 max-h-[100dvh] w-full object-contain" />
                        </DialogContent>
                    </Dialog>
                </div>
            </MaxWidthWrapper>
        </motion.div>;
};
export default DownloadAppSection;